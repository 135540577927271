import React, { ReactElement, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TextField from '../components/util/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export const ContactPage = (): ReactElement => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Layout title="Contact">
      <SEO title="Contact"></SEO>
      <p>
        Thanks for visiting my website! I look forward to hearing from all
        visitors. Whether you have a question, have a job opportunity, or just
        want to say something nice, fill out the form below and I'll get back to
        you as soon as possible. I read every message! And don't forget, I will
        never spam you.
      </p>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="flex flex-col"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Box mb={3}>
          <TextField
            value={name}
            onChange={setName}
            label="First Name"
            autoFocus
            name="name"
          />
        </Box>
        <Box mb={3}>
          <TextField
            value={email}
            onChange={setEmail}
            label="Email"
            name="email"
          />
        </Box>
        <Box mb={3}>
          <TextField
            value={message}
            onChange={setMessage}
            type="email"
            label="Message"
            name="message"
            textarea
          />
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth={false}
            disabled={!name || !email || !message}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Layout>
  );
};

export default ContactPage;
