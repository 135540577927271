import React from 'react';
import clsx from 'clsx';

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  label?: string;
  textarea?: boolean;
  type?: 'email' | 'password' | 'text';
}

const TextField = React.forwardRef<InputElement, TextFieldProps>(
  (
    {
      placeholder = '',
      textarea = false,
      autoFocus = false,
      onChange,
      label,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const InputElement = textarea ? 'textarea' : 'input';
    return (
      <div className="flex flex-col">
        {label && <label className="mb-1">{label}</label>}
        <InputElement
          ref={ref as any}
          type={type}
          onChange={({ target: { value } }: InputChangeEvent) =>
            onChange(value)
          }
          className={clsx('rounded-md w-full border border-gray-400 p-3 mb-5', {
            'h-32': textarea,
          })}
          {...{ ...rest, autoFocus, placeholder }}
        />
      </div>
    );
  }
);

export default TextField;
